<template>
<div class="footer">

<div class="box">
<div class="box-item1">
<h1>快速导航</h1>
<div class="box-item-list">
<div class="title" @click="lineTo('ProductCenter')">
<strong>产品中心</strong>
</div>
<div
                        class="title-item"
                        @click="lineTo('ProductCenter/huanbaoyushuikou')"
                    >
                        海绵城市设施
                    </div>
<div
                        class="title-item"
                        @click="lineTo('ProductCenter/liuliangji')"
                    >
                        监测设备
                    </div>
<div
                        class="title-item"
                        @click="lineTo('ProductCenter/zhihuishuiwu')"
                    >
                        水务系统
                    </div>
</div>
<div class="box-item-list">
<div class="title"><strong>8455线路检测中心</strong></div>
<div class="title-item" @click="lineTo('NewsCenter/news1')">
                        深圳光明新区探索建设"海绵城市"
                    </div>
<div class="title-item" @click="lineTo('NewsCenter/news2')">
                        海沧祥露小学变身“海绵学校” 层层渗水网抵御洪灾
                    </div>
<div class="title-item" @click="lineTo('NewsCenter/news3')">
                        厦门卫视采访
                    </div>
<div class="title-item" @click="lineTo('NewsCenter/news4')">
                        市政道路：光明新区36和38号路及塘桥西路
                    </div>
</div>
<div class="box-item-list">
<div class="title"><strong>公司实力</strong></div>
<div class="title-item" @click="lineTo('CompanyStrength')">
                        详情
                    </div>
</div>
<div class="box-item-list">
<div class="title" @click="lineTo('ContactUs')">
<strong>联系我们</strong>
</div>
<div class="title-item">联系我们</div>
</div>
</div>
<div class="box-item2">
<h1>联系我们</h1>
<div class="info-box-item">
                    地址：广东省深圳市龙岗龙城街道清林西路留学生创
                </div>
<div class="info-box-item">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业园一园南区205室
                </div>
<div class="info-box-item">邮编：518000</div>
<div class="info-box-item">
                    电话：0755-26519737 | 17727811780
                </div>

</div>
<div class="box-item3">
<h1>扫码关注</h1>
<div class="code"></div>
</div>
</div>
</div>
</template>
<script>
export default {
    name: "nav-footer",
    data() {
        return {};
    },
    methods: {
        lineTo(data) {
            this.$parent.$parent.$children[0].lineTo(data);
        },
    },
};
</script>
<style lang="scss" scoped>
.footer {
    // display: inline-block;
    position: relative;
    width: 100%;
    min-height: 937px;
    background: url("../assets/img/footerBg.jpg") no-repeat center;
    background-size: cover;
    box-sizing: border-box;
    .box {
        display: flex;
        width: 1440px;
        height: 500px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        .box-item1 {
            display: flex;
            flex-wrap: wrap;
            width: 710px;
            height: 100%;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            padding: 85px 0;
            h1 {
                width: 100%;
                color: #fff;
            }
            .box-item-list {
                width: 177px;
                height: 178px;
                .title {
                    color: #ffffff90;
                    font-size: 18px;
                    line-height: 40px;
                    cursor: pointer;
                }
                .title-item {
                    line-height: 30px;
                    color: #ffffff90;
                    font-size: 16px;
                    cursor: pointer;
                    margin-top: 5px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-right: 20px;
                    box-sizing: border-box;
                }
            }
        }
        .box-item2 {
            width: 470px;
            height: 100%;
            padding: 85px 50px;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            h1 {
                width: 100%;
                color: #fff;
                margin-bottom: 60px;
            }
            .info-box-item {
                // height: 30px;
                line-height: 30px;
                font-size: 16px;
                color: #ffffff90;
            }
        }
        .box-item3 {
            width: 260px;
            height: 100%;
            padding: 85px 0;
            box-sizing: border-box;
            h1 {
                width: 100%;
                text-align: center;
                color: #fff;
                margin-bottom: 60px;
            }
            .code {
                width: 124px;
                height: 124px;
                margin: 0 auto;
                background: url("../assets/img/ewm.jpg") no-repeat center;
                background-size: cover;
            }
        }
    }
}
</style>